import Seo from 'src/components/seo/Seo'

import SacolasReutilizaveis from '../components/SacolasReutilizaveis'

function DecathlonItau() {
  return (
    <>
      <Seo title="Sacolas Reutilizáveis | Decathlon" />
      <SacolasReutilizaveis />
    </>
  )
}

export default DecathlonItau
